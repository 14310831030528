export default function Button(props) {
    return (
      <button
        className={props.style + " " + props.color}
        onClick={props.onclick}
        type={props.type}
      >
        {props.title}
      </button>
    );
  }